import React from 'react';
import { request, request_file } from './request.js';
import './Principal.css';
import './open-iconic.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'jquery/dist/jquery.min.js';
import Swal from 'sweetalert2';

class Principal extends React.Component
{
	constructor(props){
		super(props);

		this.state={
			alumnos : [],
			cargando : 0,
			cargandofoto : 0,
			pornombre : 1,
			nombre : '',
			semestre : '3',
			grupo : 'A',
			turno : 'M',
			especialidad : 'P',
			ph : 'Escribe aqui el apellido paterno',
			seleccionado : {
				id : 0,
				estado : 0,
				pos : 0,
				foto : '',
				nombrefoto : "",
				observaciones : ''
			}
		}
	}

	componentDidMount()
	{
		this.mostrar_lista('4');
	}

	handleInputChange=(event)=>
	{
		const target = event.target;
		const value = target.value;
		if( (value!=="2") && (value!=="7") && (value!=="10") ) this.mostrar_lista(value);
	}

	handleInputChange2=(event)=>
	{
		const target = event.target;
		const value = target.value;
		const name = target.name;

		var { semestre, grupo, turno, especialidad } = this.state;

		if(name==="selectsem") semestre=value;
		if(name==="selectgru") grupo=value;
		if(name==="selecttur") turno=value;
		if(name==="selectesp") especialidad=value;

		this.setState({ semestre : semestre, grupo : grupo, turno : turno, especialidad : especialidad });
	}

	handleInputChange3=(event)=>
	{
		const target = event.target;
		const value = target.value;

		var { ph, pornombre } = this.state;
		pornombre=value;

		switch(value)
		{
			case '1': ph="Escribe aqui el apellido paterno"; break; 
			case '2': ph="Escribe aqui el apellido materno";	break;
			case '3': ph="Escribe aqui el nombre"; break;
			case '4': ph="Escribe aqui el numero de control"; break;
			default:
		}
		this.setState({ ph : ph, pornombre : pornombre });
	}

	handleInputChange4=(event)=>
	{
		const value = event.target.value;

		var { nombre } = this.state;
		nombre=value;

		this.setState({ nombre : nombre });
	}

	handleTextChange=(event)=>
	{
		const target = event.target;
		const value = target.value;
		const name = target.name;

		var { seleccionado } = this.state;
		seleccionado[name] = value;
		this.setState({ seleccionado : seleccionado });
	}

	mostrar_lista=(op)=>
	{
		var { alumnos, opcion, cargando, seleccionado } = this.state;
		let temp = this;

		cargando = 1;
		seleccionado.id=0;

		opcion = op;

		switch(opcion) {
			case '1':
				request.get('/TodasLosAlumnos')
				.then(function(response)
				{
					if(response.status === 200)
					{
						if(response.data)
						{
							alumnos = response.data;
							cargando = 0;
							temp.setState({ alumnos : alumnos, cargando : cargando });
						}
					}
				})
				.catch(error => { });
			break;
			case '3':
				request.get('/AlumnosSinFoto')
				.then(function(response)
				{
					if(response.status === 200)
					{
						if(response.data)
						{
							alumnos = response.data;
							cargando = 0;
							temp.setState({ alumnos : alumnos, cargando : cargando });
						}
					}
				})
				.catch(error => { });
			break;
			case '4':
				request.get('/FotosPendientes')
				.then(function(response)
				{
					if(response.status === 200)
					{
						if(response.data)
						{
							alumnos = response.data;
							cargando = 0;
							temp.setState({ alumnos : alumnos, cargando : cargando });
						}
					}
				})
				.catch(error => { });
			break;
			case '5':
				request.get('/FotosAutorizadas')
				.then(function(response)
				{
					if(response.status === 200)
					{
						if(response.data)
						{
							alumnos = response.data;
							cargando = 0;
							temp.setState({ alumnos : alumnos, cargando : cargando });
						}
					}
				})
				.catch(error => { });
			break;
			case '6':
				request.get('/FotosRechazadas')
				.then(function(response)
				{
					if(response.status === 200)
					{
						if(response.data)
						{
							alumnos = response.data;
							cargando = 0;
							temp.setState({ alumnos : alumnos, cargando : cargando });
						}
					}
				})
				.catch(error => { });
			break;
			case '8':
				cargando=0;
				alumnos=[];
			break;
			case '9':
				cargando=0;
				alumnos=[];
			break;
			default:
		}

		this.setState({ cargando : cargando, opcion : opcion, alumnos : alumnos, seleccionado : seleccionado });
	}

	buscarNombre(event, pornombre, nombre)
	{
		var { alumnos, cargando, seleccionado } = this.state;
		let temp = this;

		cargando = 1;
		seleccionado.id=0;

		request.get(`/FotosPorNombre/${pornombre}/${nombre}`)
		.then(function(response)
		{
			if(response.status === 200)
			{
				if(response.data)
				{
					alumnos = response.data;
					cargando = 0;
					temp.setState({ alumnos : alumnos, cargando : cargando });
				}
			}
		})
		.catch(error => { });

		this.setState({ cargando : cargando, alumnos : alumnos, seleccionado : seleccionado });
	}

	buscarGrupo(event, semestre, grupo, turno, especialidad)
	{
		var { alumnos, cargando, seleccionado } = this.state;
		let temp = this;

		cargando = 1;

		request.get(`/FPG/${semestre}/${grupo}/${turno}/${especialidad}`)
		.then(function(response)
		{
			if(response.status === 200)
			{
				if(response.data)
				{
					alumnos = response.data;
					cargando = 0;
					temp.setState({ alumnos : alumnos, cargando : cargando });
				}
			}
		})
		.catch(error => { });

		this.setState({ cargando : cargando, alumnos : alumnos, seleccionado : seleccionado });
	}

	revisar(event,pos,id)
	{
		
		var { alumnos, seleccionado, cargandofoto } = this.state;
		let temp = this;
		var num_control=0;

		seleccionado.id = id;
		seleccionado.estado = alumnos[pos].check10;
		seleccionado.pos = pos;
		seleccionado.observaciones = alumnos[pos].observaciones;
		num_control = alumnos[pos].num_control;
		seleccionado.nombrefoto = ""+num_control+".jpg";
		cargandofoto=1;

		request_file()
		.post(`/RevisarFoto?num_control=${num_control}`)
		.then(response => {
			const file = new Blob([response.data], {type: 'image/jpeg' } );
			const fileURL = URL.createObjectURL(file);
			seleccionado.foto=fileURL;
			cargandofoto=0;
			temp.setState({ seleccionado : seleccionado, cargandofoto : cargandofoto });
		})
		.catch(error => { cargandofoto=0; });

		this.setState({ seleccionado : seleccionado, cargandofoto : cargandofoto });
	}

	autorizar=()=>
	{
		var { alumnos, seleccionado } = this.state;
		let temp = this;

		request.get(`/AutorizarFoto/${seleccionado.id}`)
		.then(function(response)
		{
			if(response.status === 200)
			{
				if(response.data)
				{
					alumnos[seleccionado.pos].check10 = 3;
					alumnos[seleccionado.pos].observaciones="";
					Swal.fire({
						toast: true,
						position: 'top-end',
						icon: 'success',
						title: 'Fotografia autorizada',
						showConfirmButton: false,
						timer: 1500
					});
					temp.setState({ alumnos : alumnos });
				}
			}
		})
		.catch(error => { });
	}

	rechazar(observaciones)
	{

		var { alumnos, seleccionado } = this.state;
		let temp = this;

		seleccionado.observaciones = observaciones;

		request.post('/RechazarFoto',seleccionado)
		.then(function(response)
		{
			if(response.status === 200)
			{
				if(response.data)
				{
					alumnos[seleccionado.pos].check10 = 4;
					alumnos[seleccionado.pos].observaciones = observaciones;
					Swal.fire({
						toast: true,
						position: 'top-end',
						icon: 'success',
						title: 'Los datos fueron guardados',
						showConfirmButton: false,
						timer: 1500
					});
					temp.setState({ alumnos : alumnos });
				}
			}
		})
		.catch(error => { });
	}

	render(){

		let { alumnos, opcion, semestre, grupo, turno, especialidad, cargando, cargandofoto, seleccionado, ph, pornombre, nombre } = this.state;

		return (
			<div>
				<div className="titulo"> 
					R E V I S I O N &nbsp;&nbsp;&nbsp; D E &nbsp;&nbsp;&nbsp; F O T O G R A F I A S
				</div>

				<div className="botones">

					<div className="input-group mb-3 grande">
						<div className="input-group-prepend">
							<label className="input-group-text" htmlFor="filtros">Buscar</label>
						</div>
						<select className ="custom-select grande" name="filtros" value={opcion} id="filtros" onChange={this.handleInputChange}>
							<option value =  "1"> Todas los alumnos</option>
							<option value =  "2"> - - - - - - - - - - - </option>
							<option value =  "3"> Alumnos sin foto </option>
							<option value =  "4"> Fotos por autorizar </option>
							<option value =  "5"> Fotos autorizadas </option>
							<option value =  "6"> Fotos rechazadas </option>
							<option value =  "7"> - - - - - - - - - - - </option>
							<option value =  "8"> Por nombre o numero de control</option>
							<option value =  "9"> Por grupo </option>
						</select>
					</div>

					{
						parseInt(opcion,10) === 8 ?
							<div>
								<div className="input-group mb-3">
									<div className="input-group-prepend">
										<label className="input-group-text" htmlFor="pornombre">Buscar registros </label>
									</div>
									<select className ="custom-select" name="pornombre" value={pornombre} id="pornombre" onChange={this.handleInputChange3}>
										<option value = "1"> Por Apellido Paterno </option>
										<option value = "2"> Por Apellido Materno </option>
										<option value = "3"> Por Nombre </option>
										<option value = "4"> Por Numero De Control </option>
									</select>
									<input
										type="text" className="form-control i" placeholder={ph} 
										aria-label="Nombre" aria-describedby="basic-addon1"
										value={nombre} onChange={this.handleInputChange4}
									/>
									<div className="input-group-append">
										<button className="btn btn-outline-secondary" type="button" onClick={(e)=>this.buscarNombre(e,pornombre,nombre)}> Buscar </button>
									</div>
								</div>
							</div>
						:
							""
					}
					
					{
						parseInt(opcion,10) === 9 ?
							<div>
								<div className="input-group mb-3">
									<div className="input-group-prepend">
										<label className="input-group-text" htmlFor="porgrupo">Seleccionar grupo: </label>
									</div>
									<select className ="custom-select" name="selectsem" value={semestre} id="selectsem" onChange={this.handleInputChange2}>
										<option value = "3"> Tercero </option>
										<option value = "5"> Quinto </option>
									</select>
									<select className ="custom-select" name="selectgru" value={grupo} id="selectgru" onChange={this.handleInputChange2}>
										<option value = "A"> A </option>
										<option value = "B"> B </option>
									</select>
									<select className ="custom-select" name="selecttur" value={turno} id="selecttur" onChange={this.handleInputChange2}>
										<option value = "M"> MATUTINO </option>
										<option value = "V"> VESPERTINO </option>
									</select>
									<select className ="custom-select" name="selectesp" value={especialidad} id="selectesp" onChange={this.handleInputChange2}>
										<option value = "A"> ALIMENTOS </option>
										<option value = "P"> PROGRAMACION </option>
										<option value = "O"> OFIMATICA </option>
										<option value = "C"> CONTABILIDAD </option>
										<option value = "E"> ELECTRONICA </option>
										<option value = "L"> LABORATORISTA </option>										
										<option value = "M"> MANTENIMIENTO </option>
									</select>
									<div className="input-group-append">
										<button className="btn btn-outline-secondary" type="button" onClick={(e)=>this.buscarGrupo(e,semestre,grupo,turno,especialidad)}>Buscar</button>
									</div>


								</div>
							</div>
						:
							""
					}


				</div>

				<div className="abajo">

					<div className="divdatos">
					{
						cargando === 1 ?
							<span className="b az mar"> Cargando... </span>
						:
							alumnos.length === 0 ?
								<span className="b ro mar"> No hay registros para mostrar </span>
							:
								
								<div className="info">
									<table>
										<thead>
											<tr className="head">
												<td className="c"> No. </td>
												<td className="c"> Matricula </td>
												<td className="g izq"> &nbsp;&nbsp;Nombre </td>
												<td className="m"> Estado </td>
												<td className="m"> Revisar </td>
											</tr>
										</thead>
										<tbody>
										{
											alumnos.map((item,i) =>
												<tr key = { i }>
													<td className="c"> { i+1 } </td>
													<td className="c"> { item.num_control } </td>
													<td className="g izq"> &nbsp;&nbsp;{ item.apellido_paterno} {item.apellido_materno}, {item.nombre } </td>
													<td className="m">
														{ parseInt(item.check10,10) === 2 ? <span className="am b"> Pendiente </span> : "" }
														{ parseInt(item.check10,10) === 3 ? <span className="ve b"> Autorizada </span> : "" }
														{ parseInt(item.check10,10) === 4 ? <span className="ro b"> Rechazada </span> : "" }
													</td>
													<td className="m">
													{
														parseInt(item.check10,10) > 1 ?
															<button type="button" onClick={(e)=>this.revisar(e,i,item.id)}> Revisar </button>
														:
															""
													}
													</td>
												</tr>
											)
										}
										</tbody>
									</table>
								</div>
					}
					</div>

					<div className="divfotos">
						{
							seleccionado.id === 0 ?
								""
							:
								cargandofoto === 1 ?
									<span className="b az mar"> Cargando imagen... </span>
								:
								<div>
									<img src={seleccionado.foto} />
									<textarea
										className="observaciones" placeholder="Observaciones"
										name="observaciones" rows="3"
										value={seleccionado.observaciones || ''}
										onChange={ this.handleTextChange }>
									</textarea>
									<button type="button" onClick={ this.autorizar }> Autorizar </button>
									&nbsp;&nbsp;
									<button type="button" onClick={(e)=>this.rechazar(seleccionado.observaciones) }> Rechazar y enviar observaciones</button>
									&nbsp;&nbsp;
									<a href={seleccionado.foto} target="_blank" rel="noopener noreferrer" download={seleccionado.nombrefoto}> 
										<button type="button">
											<span className="oi am" data-glyph="data-transfer-download" aria-hidden="true"> </span>
										</button>
									 </a>
								</div>
						}
					</div>

				</div>
			</div>
		);
	}
}

export default Principal;